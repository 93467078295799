import { Component, Input, OnInit } from '@angular/core';
import { TableroService } from '../../services/tablero.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dlgMensajeError } from 'src/app/components/dlgMensajeError/dlgMensajeError.component';

@Component({
    selector: 'Detalle',
    templateUrl: './detalle.component.html',
    styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {

    @Input('parametro') parametro: any;
    public data: any;
    public columnas: any = [];
    public tipos: any = [];

    constructor(
        private servicio: TableroService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        console.log(this.parametro);
        this.servicio.getDetalle(this.parametro.id, this.parametro.param1, this.parametro.param2).subscribe(resp => {
            if (resp.numero > 0) {
                this.data = resp.respuesta;
                for (var key in this.data[0]) {
                    this.columnas.push(key);
                }
            } else {
                const modalRef = this.modalService.open(dlgMensajeError);
                modalRef.componentInstance.encabezado = resp.titulo;
                modalRef.componentInstance.contenido = resp.mensaje;
            }
        });
    }

    public typeOf(value) {
        return typeof value;
    }
}
