<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
    </ul>
</div>
<section>
    <app-loader></app-loader>
    <h1>{{ 'principal.Arcarius' | translate }}</h1>
    <div *ngFor="let grupo of data" class="jumbotron jumbotron-fluid">
        <div class="">
            <h3 style="color: #e91e63" [innerHtml]="grupo.descripcion"></h3>
            <p [innerHtml]="grupo.observaciones"></p>
            <div *ngFor="let detalle of grupo.detalle">
                <h5 class="display-6" [innerText]="detalle.descripcion"></h5>
                <p [innerHtml]="detalle.observaciones | safeHtml"></p>
                <div class="row row-cols-1 row-cols-md-3 g-4">
                    <div class="col" *ngFor="let item of detalle.dato">
                        <div class="card">
                            <div class="card-detail">
                                <h5 class="card-title-tablero" [innerHtml]="item.descripcion | safeHtml"></h5>
                                <p class="card-text" [innerHtml]="item.observaciones | safeHtml"></p>
                                <a [routerLink]="['/tablero', 'tablero2']"
                                    [queryParams]="{id: detalle.id, id2: item.id}" class="btn btn-enlace">
                                    Tablero
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>