<div class="breadcrumb_navbar">
    <ul class="breadcrumb">
        <li><a>{{this.breadcrumbs1}}</a></li>
    </ul>
</div>
<section>

    <app-loader></app-loader>

    <!-- Generacion de Componentes de Tipo Chica == 1-->
    <div class="row">
        <div class="e-card playing">
            <div class="image"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="wave"></div>
            <div class="infotop">
                <img src="/assets/iconos/logo_azul.png" style="width: 50px;">
                <br>
                UI / EX Designer
            </div>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-2 estilo" *ngFor="let consulta of dataChico">
            <div class="card" *ngIf="consulta.tipo == 1 && consulta.medida == 1">
                <!--Grafico de Barras-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' [palettes]='consulta.colors' (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 2 && consulta.medida == 1">
                <!--Grafico de Pie-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;'
                        [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center'
                        [tooltip]="tooltip" (pointClick)='pointClick($event)'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true'
                                explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 3 && consulta.medida == 1">
                <!--Grafico de Dona-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;'
                        [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip"
                        (pointClick)='pointClick($event)'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true'
                                explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 4 && consulta.medida == 1">
                <!--Grafico de Lineas-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [tooltip]='tooltip'
                        (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [width]='2' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 5 && consulta.medida == 1">
                <!--informacion en Tabla-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="min-height: 300px;">
                    <ejs-grid [dataSource]='consulta.dato' height='100%'>
                    </ejs-grid>
                    <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
                </div>
            </div>
            <div class="card" *ngIf="consulta.tipo == 6 && consulta.medida == 1">
                <!--Grafico de Barra y Linea-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato; let i = index"
                                [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name'
                                xName='descripcion' yName='valor' tooltipMappingName='link' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 7 && consulta.medida == 1">
                <!--Grafico de Embudo-->
            </div>
            <div class="card" *ngIf="consulta.tipo == 8 && consulta.medida == 1">
                <!--No aplica, es para exportacion de datos-->
            </div>
            <div class="card" *ngIf="consulta.tipo == 9 && consulta.medida == 1">
                <!--Tarjeta-->
                <div class="card-header"
                    style="background-color: #424141; color: white;flex: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                    [innerHTML]="consulta.descripcion | safeHtml">
                </div>
                <div class="card-detail" style="min-height: 70px; padding-left: 5px; padding-top: 5px;">
                    <div [innerHTML]="consulta.valor | safeHtml" style="overflow: auto;"></div>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 10 && consulta.medida == 1">
                <!--Odometro-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <ejs-circulargauge style='display:block;' centerY='70%' (pointClick)='pointClick($event)'>
                    <e-axes>
                        <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270
                            endAngle=90>
                            <e-pointers>
                                <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30'
                                    radius='80%' type='RangeBar'>
                                </e-pointer>
                                <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9'
                                    radius='90%'>
                                </e-pointer>
                            </e-pointers>
                            <e-ranges>
                                <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}'
                                    end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                            </e-ranges>
                        </e-axis>
                    </e-axes>
                </ejs-circulargauge>
            </div>
        </div>
    </div>

    <!-- Generacion de Componentes de Tipo Mediana == 2-->
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 estilo" *ngFor="let consulta of dataMediano">
            <div class="card" *ngIf="consulta.tipo == 1 && consulta.medida == 2">
                <!--Grafico de Barras-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 2 && consulta.medida == 2">
                <!--Grafico de Pie-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;'
                        [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center'
                        [tooltip]="tooltip" (pointClick)='pointClick($event)'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true'
                                explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 3 && consulta.medida == 2">
                <!--Grafico de Dona-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;'
                        [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip"
                        (pointClick)='pointClick($event)'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true'
                                explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 4 && consulta.medida == 2">
                <!--Grafico de Lineas-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [legendSettings]="legendSettings"
                        [primaryXAxis]='primaryXAxis' [primaryYAxis]='primaryYAxis' [chartArea]='chartArea'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [width]='2' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 5 && consulta.medida == 2">
                <!--informacion en Tabla-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-grid [dataSource]='consulta.dato' height='100%'>
                    </ejs-grid>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 6 && consulta.medida == 2">
                <!--Grafico de Barra y Linea-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato; let i = index"
                                [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name'
                                xName='descripcion' yName='valor' tooltipMappingName='link' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 7 && consulta.medida == 2">
                <!--Grafico de Embudo-->
            </div>
            <div class="card" *ngIf="consulta.tipo == 8 && consulta.medida == 2">
                <!--No aplica, es para exportacion de datos-->
            </div>
            <div class="card" *ngIf="consulta.tipo == 9 && consulta.medida == 2" style="max-height: 400px;">
                <!--Tarjeta-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <div [innerHTML]="consulta.valor | safeHtml"></div>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 10 && consulta.medida == 2">
                <!--Odometro-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <ejs-circulargauge style='display:block;' centerY='70%' (pointClick)='pointClick($event)'>
                    <e-axes>
                        <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270
                            endAngle=90>
                            <e-pointers>
                                <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30'
                                    radius='80%' type='RangeBar'>
                                </e-pointer>
                                <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9'
                                    radius='90%'>
                                </e-pointer>
                            </e-pointers>
                            <e-ranges>
                                <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}'
                                    end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                            </e-ranges>
                        </e-axis>

                    </e-axes>
                </ejs-circulargauge>
            </div>
            <div class="card" *ngIf="consulta.tipo == 12 && consulta.medida == 2">
                <!--Grafico de Chart JS-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="max-height: 300px; overflow: auto;">
                    <canvas baseChart [data]="consulta.dato" (chartClick)="chartClicked($event)">
                    </canvas>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 13 && consulta.medida == 2">
                <!--Grafico de Barras-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='StackingColumn'
                                [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor'
                                tooltipMappingName='link' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
        </div>
    </div>

    <!-- Generacion de Componentes de Tipo Grande == 3-->
    <div class="row">
        <div class="col-12 estilo" *ngFor="let consulta of dataGrande">
            <div class="card" *ngIf="consulta.tipo == 1 && consulta.medida == 3">
                <!--Grafico de Barras-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' [palettes]='consulta.colors' (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Column' [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 2 && consulta.medida == 3">
                <!--Grafico de Pie-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;'
                        [legendSettings]="legendSettings" [enableAnimation]='enableAnimation' [center]='center'
                        [tooltip]="tooltip" (pointClick)='pointClick($event)'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [startAngle]="0" [endAngle]="360" innerRadius="0%" radius="100%" [explode]='true'
                                explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 3 && consulta.medida == 3">
                <!--Grafico de Dona-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-accumulationchart style='display:block; width: 92%; height: 80%;'
                        [enableAnimation]='enableAnimation' [center]='center' [tooltip]="tooltip"
                        (pointClick)='pointClick($event)'>
                        <e-accumulation-series-collection>
                            <e-accumulation-series *ngFor="let registro of consulta.dato" [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [startAngle]="0" [endAngle]="360" innerRadius="40%" radius="100%" [explode]='true'
                                explodeOffset='10%' [palettes]='consulta.colors'>
                            </e-accumulation-series>
                        </e-accumulation-series-collection>
                    </ejs-accumulationchart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 4 && consulta.medida == 3">
                <!--Grafico de Lineas-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [primaryYAxis]='primaryYAxis' [chartArea]='chartArea' [tooltip]='tooltip'
                        (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='Line' [dataSource]="registro.data"
                                [name]='registro.name' xName='descripcion' yName='valor' tooltipMappingName='link'
                                [width]='2' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 5 && consulta.medida == 3">
                <!--informacion en Tabla-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-grid [dataSource]='consulta.dato' height='100%'>
                    </ejs-grid>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 6 && consulta.medida == 3">
                <!--Grafico de Barra y Linea-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato; let i = index"
                                [type]="i==0?'Column':'Line'" [dataSource]="registro.data" [name]='registro.name'
                                xName='descripcion' yName='valor' tooltipMappingName='link' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 7 && consulta.medida == 3">
                <!--Grafico de Embudo-->
            </div>
            <div class="card" *ngIf="consulta.tipo == 8 && consulta.medida == 3">
                <!--No aplica, es para exportacion de datos-->
            </div>
            <div class="card" *ngIf="consulta.tipo == 9 && consulta.medida == 3">
                <!--Tarjeta-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary " style="height: 300px; overflow: auto;">
                    <div [innerHTML]="consulta.valor | safeHtml"></div>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 10 && consulta.medida == 3">
                <!--Odometro-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary " style="height: 300px; overflow: auto;">
                    <ejs-circulargauge style='display:block;' centerY='70%' (pointClick)='pointClick($event)'>
                        <e-axes>
                            <e-axis minimum='{{consulta.dato.minimo}}' maximum='{{consulta.dato.maximo}}' startAngle=270
                                endAngle=90>
                                <e-pointers>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#3e494f' pointerWidth='30'
                                        radius='80%' type='RangeBar'>
                                    </e-pointer>
                                    <e-pointer value='{{consulta.dato.valor}}' color='#424242' pointerWidth='9'
                                        radius='90%'>
                                    </e-pointer>
                                </e-pointers>
                                <e-ranges>
                                    <e-range *ngFor="let rango of consulta.dato.rangos" start='{{rango.inicia}}'
                                        end='{{rango.termina}}' radius='110%' color='{{rango.color}}'></e-range>
                                </e-ranges>
                            </e-axis>
                        </e-axes>
                    </ejs-circulargauge>
                </div>
            </div>
            <div class="card" *ngIf="consulta.tipo == 12 && consulta.medida == 3">
                <!--Grafico de Chart JS-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 400px; overflow: auto;">
                    <canvas baseChart [data]="consulta.dato" (chartClick)="chartClicked($event)">
                    </canvas>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
            <div class="card" *ngIf="consulta.tipo == 13 && consulta.medida == 3">
                <!--Grafico de Barras-->
                <div class="card-header" [innerHTML]="consulta.descripcion | safeHtml"></div>
                <div class="card-detail text-primary" style="height: 300px; overflow: auto;">
                    <ejs-chart style='display:block; width: 92%; height: 80%;' [primaryXAxis]='primaryXAxis'
                        [tooltip]='tooltip' (tooltipRender)="tooltipRender($event)" [palettes]='consulta.colors'
                        (pointClick)='pointClick($event)'>
                        <e-series-collection>
                            <e-series *ngFor="let registro of consulta.dato" type='StackingColumn'
                                [dataSource]="registro.data" [name]='registro.name' xName='descripcion' yName='valor'
                                tooltipMappingName='link' [marker]='marker'>
                            </e-series>
                        </e-series-collection>
                    </ejs-chart>
                </div>
                <span class="subtitle" [innerHtml]="consulta.observaciones | safeHtml"></span>
            </div>
        </div>
    </div>
</section>